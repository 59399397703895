import React from "react";
import "./logo.scss";
import { Link } from "react-router-dom";

const Logo = () => {
  const handleToggleSideBar = () => {
    document.body.classList.toggle("toggle-sidebar");
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <Link to="/dashboard" className="logo d-flex align-items-center">
        <span className="d-none d-lg-block">Professor Dashboard</span>
        <i
          className="bi bi-list toggle-sidebar-btn"
          onClick={handleToggleSideBar}
        ></i>
      </Link>
    </div>
  );
};

export default Logo;
