import React from "react";
import { ResponsiveLine } from "@nivo/line";
import "./lineChart.scss";

const TopPerformingCoursesLineChart = ({ data }) => {
  const maxYValue = Math.max(
    ...data.flatMap((serie) => serie.data.map((point) => point.y))
  );
  const adjustedMaxYValue = maxYValue + 1;

  const generateTickValues = (maxValue) => {
    const ticks = [];
    for (let i = 0; i <= maxValue; i += 1) {
      ticks.push(i);
    }
    return ticks;
  };

  const tickValues = generateTickValues(adjustedMaxYValue);

  const sortedData = data.map((serie) => ({
    ...serie,
    data: serie.data.sort((a, b) => a.x.localeCompare(b.x)),
  }));

  return (
    <div
      className="line-chart-container"
      style={{ height: 400, width: "100%" }}
    >
      <ResponsiveLine
        data={sortedData}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: adjustedMaxYValue,
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Chapter Name",
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number Of Students",
          legendOffset: -40,
          legendPosition: "middle",
          tickValues: tickValues,
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableArea={true}
        enableCrosshair={true}
        useMesh={true}
        tooltip={({ point }) => (
          <div
            style={{
              backgroundColor: "#a9cafd",
              borderRadius: "20px",
              border: "1px solid #ccc",
              padding: "15px",
            }}
          >
            <strong>Course:</strong> {point.serieId}
            <br />
            <strong>Chapter:</strong> {point.data.x}
            <br />
            <strong>Number Of Students:</strong> {point.data.y}
          </div>
        )}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default TopPerformingCoursesLineChart;
