// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  width: 100%;
  height: 55px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: relative;
}
.input-field::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(to right, #177AD6, #AF14EF);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
}
.input-field input {
  flex: 1 1;
  height: 100%;
  background-color: #fff;
  outline: none;
  border: none;
  padding: 5px;
  font-size: 18px;
  color: black;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}
.input-field input:-webkit-autofill, .input-field input:-webkit-autofill:hover, .input-field input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-text-fill-color: black;
}
.input-field .icon {
  font-size: 25px;
  cursor: pointer;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/email-input/emailInput.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,mBAAA;EACA,YAAA;EACA,uDAAA;EACA,8EACE;EAGF,uBAAA;AADJ;AAIE;EACE,SAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAFJ;AAII;EAIE,qCAAA;EACA,8BAAA;AAJN;AAQE;EACE,eAAA;EACA,eAAA;EACA,UAAA;AANJ","sourcesContent":[".input-field {\n  width: 100%;\n  height: 55px;\n  background-color: #fff;\n  border-radius: 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 15px;\n  position: relative;\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    border-radius: 20px;\n    padding: 2px;\n    background: linear-gradient(to right, #177AD6, #AF14EF);\n    -webkit-mask: \n      linear-gradient(#fff 0 0) content-box, \n      linear-gradient(#fff 0 0);\n    -webkit-mask-composite: destination-out;\n    mask-composite: exclude;\n  }\n\n  input {\n    flex: 1;\n    height: 100%;\n    background-color: #fff;\n    outline: none;\n    border: none;\n    padding: 5px;\n    font-size: 18px;\n    color: black;\n    overflow: hidden;\n    border-radius: 20px;\n    position: relative;\n\n    &:-webkit-autofill,\n    &:-webkit-autofill:hover,\n    &:-webkit-autofill:focus {\n      -webkit-box-shadow: 0 0 0px 1000px #fff inset;\n      box-shadow: 0 0 0px 1000px #fff inset;\n      -webkit-text-fill-color: black;\n    }\n  }\n\n  .icon {\n    font-size: 25px;\n    cursor: pointer;\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
