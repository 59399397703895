import React, { useState, useEffect } from "react";
import "./headsetorientation.scss";
import HumanDirections from "./HumanDirections";

const HeadsetOrientation = ({ data }) => {
  const [transformedData, setTransformedData] = useState([]);

  useEffect(() => {
    const formattedData = Object.keys(data).map((key) => ({
      position: key,
      value: data[key],
    }));
    
    setTransformedData(formattedData);
  }, [data]);

  return (
    <div className="headset-orientation-container">
      <h6>Headset Orientation</h6>
      <div className="chart-wrapper">
        <HumanDirections data={transformedData}/>
      </div>
    </div>
  );
};

export default HeadsetOrientation;
