import React, { useState } from "react";
import "./courses.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Courses = ({ data, onUpdate, onDelete }) => {
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [editedCourseName, setEditedCourseName] = useState("");
  const [editedDuration, setEditedDuration] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
    setEditedCourseName(data.courseName);
    setEditedDuration(data.duration);
    setSelectedImage(null);
  };

  const closePopup = () => {
    setShowPopup(false);
    setEditedCourseName("");
    setSelectedImage(null);
    setSelectedImageFile(null);
  };

  const handleCourseNameChange = (e) => {
    setEditedCourseName(e.target.value);
  };

  const handleDurationChange = (e) => {
    setEditedDuration(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const saveChanges = async () => {
    const formData = new FormData();
    formData.append("courseName", editedCourseName);
    formData.append("duration", editedDuration);
    if (selectedImageFile) {
      formData.append("thumbnail", selectedImageFile);
    }

    try {
      const response = await fetch(
        `${SERVER_URL}/course/update/${data._id}?collegeId=${currentUser.collegeId}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedCourse = await response.json();
        console.log("Course updated:", updatedCourse);
        onUpdate(updatedCourse);
      } else {
        console.error("Failed to update course");
      }
    } catch (error) {
      console.error("Error:", error);
    }

    closePopup();
  };

  const deleteCourse = async () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/course/delete/${data._id}?collegeId=${currentUser.collegeId}`
      );
      if (response.status === 200) {
        onDelete(data._id);
      }
    } catch (error) {
      console.error("Failed to delete course:", error);
    } finally {
      setShowDeleteConfirmation(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div className="content-container">
      <div className="content-container-details">
        <div className="content-top">
          <div className="heading">
            <h1>{data.courseName}</h1>
          </div>
          <div className="details">
            <img
              src={
                selectedImage
                  ? selectedImage
                  : `${SERVER_URL}/${data.thumbnail}`
              }
              alt="course_thumbnail"
            />
            {showPopup && (
              <label className="change-image-btn">
                Change Thumbnail
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </label>
            )}
          </div>
        </div>
        <div className="courses-button-wrapper">
          <div
            className="button"
            onClick={() => navigate(`/content-management/${data.courseName}`)}
          >
            <div className="icon">
              <i className="bi bi-eye"></i>
            </div>
            <span>Show Content</span>
          </div>
          <div className="button" onClick={openPopup}>
            <div className="icon">
              <i className="bi bi-pencil-square"></i>
            </div>
            <span>Edit Content</span>
          </div>
          <div className="button" onClick={deleteCourse}>
            <div className="icon">
              <i className="bi bi-trash"></i>
            </div>
            <span>Delete</span>
          </div>
        </div>
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={closePopup}>
              &times;
            </span>
            <h2>Edit Course</h2>
            <div className="edit-form">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={editedCourseName}
                  onChange={handleCourseNameChange}
                  required
                />
                <label htmlFor="name">Course Name</label>
              </div>
              <br />
              <img
                src={
                  selectedImage
                    ? selectedImage
                    : `${SERVER_URL}/${data.thumbnail}`
                }
                alt="course_thumbnail"
              />
              <label className="change-image-btn">
                Change Thumbnail
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
              </label>
              <div className="form-group">
                <input
                  type="number"
                  id="duration"
                  name="duration"
                  value={editedDuration}
                  onChange={handleDurationChange}
                  required
                />
                <label htmlFor="duration">Duration In Days</label>
              </div>
            </div>
            <button onClick={saveChanges}>Save Changes</button>
          </div>
        </div>
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmationDialog
          onCancel={cancelDelete}
          onConfirm={confirmDelete}
        />
      )}
    </div>
  );
};

export default Courses;
