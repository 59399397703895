import React from "react";
import CognitionAnalysis from "../../pages/cognition-analysis/CognitionAnalysis";

const CognitionAnalysisMain = () => {
  return (
    <main id="main" className="main">
      <CognitionAnalysis />
    </main>
  );
};

export default CognitionAnalysisMain;
