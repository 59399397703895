import React from "react";
import "./nav.scss";
import NavMessages from "./navmessages/NavMessages";
import NavAvatar from "./navavatar/NavAvatar";

const Nav = () => {
  return (
    <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">
        <NavMessages />
        <NavAvatar />
      </ul>
    </nav>
  );
};

export default Nav;
