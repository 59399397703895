import React, { useState } from "react";
import "./chapterdetails.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";
import LearningPoints from "./LearningPoints";

const ChapterDetails = ({ courseId, data, refreshData, chapterData }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [chapter, setChapter] = useState("");
  const [chapterDownloadLink, setChapterDownloadLink] = useState("");

  const handleEditClick = (chapter) => {
    setChapter(chapter.chapter);
    setChapterDownloadLink(chapter.chapterDownloadLink);
    setIsModalOpen(true);
  };

  const handleDeleteClick = (chapter) => {
    setIsDeleteConfirmOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setChapter("");
    setChapterDownloadLink("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("newChapterName", chapter);
      formData.append("chapterDownloadLink", chapterDownloadLink);
      const response = await axios.put(
        `${SERVER_URL}/course/${courseId}/chapter/${data.chapter}/update?collegeId=${currentUser.collegeId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/course/${courseId}/chapter/${data.chapter}/delete?collegeId=${currentUser.collegeId}`
      );
      if (response.status === 200) {
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="ch-details">
      <div className="ch-top">
        <p>
          <b>Chapter:</b> {data.chapter}
        </p>
        <p>
          <b>Download URL:</b> {data.chapterDownloadLink}
        </p>
      </div>
      <div className="ch-middle">
        <LearningPoints data={chapterData} chapter={data.chapter} refreshData={refreshData} />
      </div>
      <div className="ch-bottom">
        <div className="button" onClick={() => handleEditClick(data)}>
          <div className="icon">
            <i className="bi bi-pencil-square"></i>
          </div>
          <span>Edit Chapter</span>
        </div>
        <div className="button" onClick={() => handleDeleteClick(data)}>
          <div className="icon">
            <i className="bi bi-trash"></i>
          </div>
          <span>Delete Chapter</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>Edit Chapter</h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="chapter">Title:</label>
                <input
                  type="text"
                  id="chapter"
                  value={chapter}
                  onChange={(e) => setChapter(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="chapterDownloadLink">Content:</label>
                <input
                  type="text"
                  id="chapterDownloadLink"
                  value={chapterDownloadLink}
                  onChange={(e) => setChapterDownloadLink(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Update</button>
            </form>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Delete Chapter</h1>
            <p>Are you sure you want to delete this chapter?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChapterDetails;
