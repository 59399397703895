import React, { useEffect, useState } from "react";
import "./user.scss";
import { useSelector } from "react-redux";

const User = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const dateParts = now.toLocaleDateString("en-GB", options).split(" ");
      const formattedDate = `${dateParts[0]}, ${dateParts[1]} ${dateParts[2]} ${dateParts[3]}`;
      setCurrentDate(formattedDate);
    };
    updateDateTime();
    const intervalId = setInterval(updateDateTime, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="user">
      <h1>Hello, {currentUser && currentUser.name}</h1>
      <span>Today is: {currentDate}</span>
    </div>
  );
};

export default User;
