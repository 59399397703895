// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  z-index: 997;
  height: 120px;
  background-color: #fff;
  padding-left: 20px;
}
.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}`, "",{"version":3,"sources":["webpack://./src/components/header/header.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,kBAAA;EACA,eAAA;EACA,cAAA;AACJ","sourcesContent":[".header {\n  z-index: 997;\n  height: 120px;\n  background-color: #fff;\n  padding-left: 20px;\n\n  .toggle-sidebar-btn {\n    font-size: 32px;\n    padding-left: 10px;\n    cursor: pointer;\n    color: #012970;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
