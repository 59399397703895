import React, { useState } from "react";
import "./learniongpointsdetails.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const LearningPointsDetails = ({
  courseId,
  chapterName,
  learningPoints,
  refreshData,
}) => {
  const { currentUser } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const handleEditClick = () => {
    setIsModalOpen(true);
    setEditedName(learningPoints.name);
  };

  const handleDeleteClick = () => {
    setIsDeleteConfirmOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditedName("");
    setSelectedImageFile(null);
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleLearningPointsNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageFile(file);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("name", editedName);
      if (selectedImageFile) {
        formData.append("image", selectedImageFile);
      }

      const response = await axios.put(
        `${SERVER_URL}/course/${courseId}/${chapterName}/${learningPoints.name}/update?collegeId=${currentUser.collegeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/course/${courseId}/${chapterName}/${learningPoints.name}/delete?collegeId=${currentUser.collegeId}`
      );
      if (response.status === 200) {
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="lp-details">
      <div className="lp-top">
        <h3>{learningPoints.name}</h3>
      </div>
      <div className="lp-img">
        <img src={`${SERVER_URL}/${learningPoints.image}`} alt="" />
      </div>
      <div className="lp-bottom">
        <div className="button" onClick={handleEditClick}>
          <div className="icon">
            <i className="bi bi-pencil-square"></i>
          </div>
          <span>Edit Content</span>
        </div>
        <div className="button" onClick={handleDeleteClick}>
          <div className="icon">
            <i className="bi bi-trash"></i>
          </div>
          <span>Delete</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>Edit Learning Points</h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="name">Title:</label>
                <input
                  type="text"
                  id="name"
                  value={editedName}
                  onChange={handleLearningPointsNameChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="image">Content:</label>
                <input type="file" id="image" onChange={handleImageChange} />
              </div>
              <button type="submit">Update</button>
            </form>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Delete Learning Point</h1>
            <p>Are you sure you want to delete this learning point?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LearningPointsDetails;
