// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
}
.user h1 {
  font-size: 26px;
  font-weight: 700;
  color: #000;
}
.user span {
  color: #555555;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/header/user/user.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AACJ;AAEE;EACE,cAAA;EACA,eAAA;AAAJ","sourcesContent":[".user {\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  margin: 10px;\n\n  h1 {\n    font-size: 26px;\n    font-weight: 700;\n    color: #000;\n  }\n\n  span{\n    color: #555555;\n    font-size: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
