import React from "react";
import "./header.scss";
import Logo from "./logo/Logo";
import Nav from "./nav/Nav";
import { useSelector } from "react-redux";
import User from "./user/User";

const Header = () => {
  const { currentUser } = useSelector((state) => state.user);
  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <Logo />
      {currentUser && (
        <>
          <User />
          <Nav />
        </>
      )}
    </header>
  );
};

export default Header;
