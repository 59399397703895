import React from "react";
import "./navavatar.scss";
import { Navigate, Link } from "react-router-dom";
import { SERVER_URL } from "../../../../utils/helper";
import axios from "axios";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import {
  signOutUserStart,
  signOutUserFailure,
  signOutUserSuccess,
} from "../../../../redux/userSlice";

const NavAvatar = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);

  const handleSignOut = async () => {
    try {
      dispatch(signOutUserStart());
      const res = await axios.post(`${SERVER_URL}/admin/logout`, {
        id: currentUser?._id,
      });
      const data = await res.data;
      if (!data || res.status === 500) {
        dispatch(signOutUserFailure(data.message));
        return;
      }
      dispatch(signOutUserSuccess(data));
      localStorage.clear();
      Cookies.remove("access_token");
      <Navigate to="/login" />;
    } catch (error) {
      dispatch(signOutUserFailure(error.message));
    }
  };

  return (
    <li className="nav-item dropdown pe-3">
      <Link
        className="nav-link nav-profile d-flex align-items-center pe-0"
        to="#"
        data-bs-toggle="dropdown"
      >
        <span className="d-none d-md-block dropdown-toggle ps-2 avatar-email">
          {currentUser && currentUser.email}
        </span>
      </Link>
      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow nav-profile">
        <li className="dropdown-header">
          <h6>{currentUser && currentUser.email}</h6>
          <span>{currentUser && currentUser.role}</span>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <Link className="dropdown-item d-flex align-items-center" to="#">
            <i className="bi bi-gear"></i>
            <span>Change Password</span>
          </Link>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <Link className="dropdown-item d-flex align-items-center" to="#">
            <i className="bi bi-box-arrow-right"></i>
            <span
              onClick={() => {
                handleSignOut();
              }}
            >
              Logout
            </span>
          </Link>
        </li>
      </ul>
    </li>
  );
};

export default NavAvatar;
