import React, { useState } from "react";
import "./allocation-details.scss";
import SwipperMain from "../../pages/allocate-content/swipper/SwipperMain";

const AllocationDetails = ({ data, onDelete }) => {
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await onDelete(data._id);
      setIsDeleteConfirmOpen(false);
    } catch (error) {
      console.error("Error deleting user analytics:", error.message);
    }
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  return (
    <div className="allocate-content-details-container">
      <div className="allocate-content-details-top">
        <p>{data.email}</p>
      </div>
      <div className="allocate-content-details-heading">
        <div className="left-group">
          <span>Assigned Courses</span>
        </div>
        <div className="right-group">
          <div className="content-row-button-wrapper">
            <div className="button" onClick={handleDeleteClick}>
              <div className="icon">
                <i className="bi bi-trash"></i>
              </div>
              <span>Delete</span>
            </div>
          </div>
        </div>
      </div>
      <div className="allocate-content-details-body">
        <SwipperMain course={data.courses} />
      </div>

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Confirm Delete</h1>
            <p>Are you sure you want to delete this record?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllocationDetails;
