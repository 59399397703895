import React, { useCallback, useEffect, useState } from "react";
import "./coursedetailspage.scss";
import { useParams, useNavigate } from "react-router-dom";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import CourseInfo from "../../components/content-management/chapter-details/CourseInfo";
import Link from "../../components/content-management/chapter-details/Link";
import Chapter from "../../components/content-management/chapter-details/Chapter";
import Owner from "../../components/content-management/chapter-details/Owner";
import Requirements from "../../components/content-management/chapter-details/Requirements";
import { useSelector } from "react-redux";

const CourseDetailsPage = () => {
  const { currentUser } = useSelector((state) => state.user);
  const { courseName } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/course/name/${courseName}?collegeId=${currentUser.collegeId}`
      );
      setData(response.data);
    } catch (error) {
      console.error(error.message);
    }
  }, [courseName,currentUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className="course-details-container">
      <div className="top">
        <div className="top-button">
          <div className="button" onClick={handleGoBack}>
            <div className="icon">
              <i className="bi bi-arrow-left-square"></i>
            </div>
            <span>Go Back</span>
          </div>
        </div>
        <div className="top-heading">
          <h1>
            <i className="bi bi-card-list"></i>Content Management
          </h1>
          <span>{data.courseName} Content</span>
        </div>
        <div className="top-image">
          <img src={`${SERVER_URL}/${data.thumbnail}`} alt="course_thumbnail" />
        </div>
      </div>
      <div className="body-content">
        <div className="section1">
          <div className="about-container">
            <CourseInfo data={data} refreshData={fetchData} />
          </div>
          <div className="link-container">
            <Link data={data} refreshData={fetchData} />
          </div>
        </div>
        <div className="section2">
          <div className="chapter-container">
            <Chapter data={data} refreshData={fetchData} />
          </div>
        </div>
        <div className="section3">
          <div className="owner-container">
            <Owner data={data} refreshData={fetchData} />
          </div>
          <div className="requirements-container">
            <Requirements data={data} refreshData={fetchData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailsPage;
