// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  width: 300px;
  max-width: 80%;
  box-sizing: border-box;
}
.delete-confirmation-dialog p {
  margin-bottom: 15px;
}
.delete-confirmation-dialog .buttons {
  display: flex;
  justify-content: center;
}
.delete-confirmation-dialog .buttons button {
  margin: 0 10px;
  padding: 8px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.delete-confirmation-dialog .buttons button:hover {
  background-color: #0056b3;
}
.delete-confirmation-dialog .buttons button:first-child {
  background-color: #6c757d;
}
.delete-confirmation-dialog .buttons button:first-child:hover {
  background-color: #5a6268;
}`, "",{"version":3,"sources":["webpack://./src/components/content-management/deleteconfirmationdialog.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,uCAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;EACA,sBAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,uBAAA;AAAJ;AAEI;EACE,cAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,sCAAA;AAAN;AAEM;EACE,yBAAA;AAAR;AAGM;EACE,yBAAA;AADR;AAGQ;EACE,yBAAA;AADV","sourcesContent":[".delete-confirmation-dialog {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  padding: 20px;\n  border: 1px solid #ccc;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  z-index: 1000;\n  text-align: center;\n  width: 300px;\n  max-width: 80%;\n  box-sizing: border-box;\n\n  p {\n    margin-bottom: 15px;\n  }\n\n  .buttons {\n    display: flex;\n    justify-content: center;\n\n    button {\n      margin: 0 10px;\n      padding: 8px 20px;\n      font-size: 16px;\n      cursor: pointer;\n      border: none;\n      outline: none;\n      background-color: #007bff;\n      color: white;\n      border-radius: 4px;\n      transition: background-color 0.3s ease;\n\n      &:hover {\n        background-color: #0056b3;\n      }\n\n      &:first-child {\n        background-color: #6c757d;\n\n        &:hover {\n          background-color: #5a6268;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
