// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-analytics {
  padding: 20px;
  background-color: #f5f5f5;
}
.user-analytics .selection {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}
.user-analytics .selection select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
}
.user-analytics .analytics-sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-analytics .analytics-sections .section {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.user-analytics .analytics-sections .section h3 {
  margin-bottom: 10px;
  font-size: 18px;
}
.user-analytics .analytics-sections .section form.course-started,
.user-analytics .analytics-sections .section form.chapter-started {
  display: flex;
  gap: 10px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/dummy_data/UserAnalyticsComponentDummyData.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;AACF;AACE;EACE,mBAAA;EACA,aAAA;EACA,SAAA;AACJ;AACI;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;AACN;AAGE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AADJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;AADN;AAGM;EACE,mBAAA;EACA,eAAA;AADR;AAIM;;EAEE,aAAA;EACA,SAAA;EACA,mBAAA;AAFR","sourcesContent":[".user-analytics {\n  padding: 20px;\n  background-color: #f5f5f5;\n\n  .selection {\n    margin-bottom: 20px;\n    display: flex;\n    gap: 10px;\n\n    select {\n      padding: 10px;\n      border: 1px solid #ccc;\n      border-radius: 4px;\n      background-color: #fff;\n      font-size: 16px;\n    }\n  }\n\n  .analytics-sections {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n\n    .section {\n      padding: 20px;\n      background-color: #fff;\n      border: 1px solid #ddd;\n      border-radius: 4px;\n\n      h3 {\n        margin-bottom: 10px;\n        font-size: 18px;\n      }\n\n      form.course-started,\n      form.chapter-started {\n        display: flex;\n        gap: 10px;\n        align-items: center;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
