import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const AttendanceBarChart = ({ data }) => {
  const normalizedData = normalizeData(data);

  const colors = {
    chapter_1: "#e36c6c",
    chapter_2: "#f5b041",
    chapter_3: "#f7dc6f",
    chapter_4: "#7d3f92",
    chapter_5: "#2ecc71",
  };

  const keys = Object.keys(normalizedData[0]).filter((key) => key !== "Date");

  const legendColors = keys.map((key) => ({
    id: key,
    label: key,
    color: colors[key] || "#000",
  }));

  const maxValue = Math.max(
    ...normalizedData.flatMap((item) => keys.map((key) => item[key] || 0))
  );

  const tickValues = Array.from(
    { length: Math.ceil(maxValue) + 1 },
    (_, i) => i
  );

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveBar
        data={normalizedData}
        keys={keys}
        indexBy="Date"
        margin={{ top: 50, right: 130, bottom: 80, left: 60 }}
        padding={0.3}
        groupMode="stacked"
        colors={({ id }) => colors[id] || "#000"}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "Date",
          legendPosition: "middle",
          legendOffset: 70,
          format: (value) => value,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Number Of Students",
          legendPosition: "middle",
          legendOffset: -40,
          tickValues: tickValues,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
            data: legendColors,
          },
        ]}
        tooltip={({ id, value, indexValue }) => (
          <div
            style={{
              backgroundColor: "#a9cafd",
              borderRadius: "4px",
              border: "1px solid #ccc",
              padding: "10px",
            }}
          >
            <strong>Date:</strong> {indexValue}
            <br />
            <strong>Chapter:</strong> {id}
            <br />
            <strong>Number Of Students:</strong> {value}
          </div>
        )}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

const normalizeData = (data) => {
  const keys = Array.from(
    new Set(
      data.flatMap((item) => Object.keys(item).filter((key) => key !== "Date"))
    )
  );

  return data.map((item) => {
    const newItem = { ...item };
    keys.forEach((key) => {
      if (!(key in newItem)) {
        newItem[key] = 0;
      }
    });
    return newItem;
  });
};

export default AttendanceBarChart;
