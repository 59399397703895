import React, { useEffect, useState } from "react";
import "./courseAssigned.scss";
import { GrTask } from "react-icons/gr";
import { useSelector } from "react-redux";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const CourseAssigned = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourseAssigned = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/students-per-chapter?collegeId=${currentUser.collegeId}`
        );

        if (Array.isArray(response.data)) {
          const sortedData = response.data.sort((a, b) => {
            const chapterA = parseInt(a.Chapter.split("_")[1], 10);
            const chapterB = parseInt(b.Chapter.split("_")[1], 10);

            return chapterA - chapterB;
          });
          setData(sortedData);
        } else {
          throw new Error("Invalid data format received from the server");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchCourseAssigned();
  }, [currentUser]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="course-assigned-container">
      <h1>
        <GrTask />
        Course Assigned
      </h1>
      <div className="stu-course-assigned">
        {data.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Course</th>
                <th>Chapter</th>
                <th>Students</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.Course}</td>
                  <td>{item.Chapter}</td>
                  <td>{item["Number of Students"]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default CourseAssigned;
