// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.human-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.human-container .human {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.human-container .direction {
  position: absolute;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
}
.human-container .direction.front {
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
}
.human-container .direction.front-right {
  top: 20%;
  left: 65%;
  transform: translateX(-50%);
  font-size: 12px;
}
.human-container .direction.back-right {
  top: 70%;
  left: 50%;
  transform: translateY(-50%);
  font-size: 12px;
}
.human-container .direction.back {
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
}
.human-container .direction.back-left {
  top: 70%;
  left: 18%;
  transform: translateY(-50%);
  font-size: 12px;
}
.human-container .direction.front-left {
  top: 20%;
  left: 32%;
  transform: translateX(-50%);
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/cognition-analysis/humandirections.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;AACJ;AAEE;EACE,kBAAA;EACA,UAAA;EACA,0CAAA;EACA,YAAA;EACA,kBAAA;AAAJ;AAEI;EACE,OAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AAAN;AAGI;EACE,QAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AADN;AAII;EACE,QAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AAFN;AAKI;EACE,UAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AAHN;AAMI;EACE,QAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AAJN;AAOI;EACE,QAAA;EACA,SAAA;EACA,2BAAA;EACA,eAAA;AALN","sourcesContent":[".human-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n\n  .human {\n    height: 100px;\n    width: 100px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 1;\n  }\n\n  .direction {\n    position: absolute;\n    z-index: 2;\n    background-color: rgba(255, 255, 255, 0.7);\n    padding: 5px;\n    border-radius: 5px;\n\n    &.front {\n      top: 5%;\n      left: 50%;\n      transform: translateX(-50%);\n      font-size: 12px;\n    }\n\n    &.front-right {\n      top: 20%;\n      left: 65%;\n      transform: translateX(-50%);\n      font-size: 12px;\n    }\n\n    &.back-right {\n      top: 70%;\n      left: 50%;\n      transform: translateY(-50%);\n      font-size: 12px;\n    }\n\n    &.back {\n      bottom: 5%;\n      left: 50%;\n      transform: translateX(-50%);\n      font-size: 12px;\n    }\n\n    &.back-left {\n      top: 70%;\n      left: 18%;\n      transform: translateY(-50%);\n      font-size: 12px;\n    }\n\n    &.front-left {\n      top: 20%;\n      left: 32%;\n      transform: translateX(-50%);\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
