import React from "react";
import "./humandirections.scss";
import headsetOrientation from "../../assets/HeadsetOrientation.png";

const HumanDirections = ({ data }) => {
  const total = data.reduce((sum, item) => sum + item.value, 0);

  const getPercentage = (position) => {
    const direction = data.find((item) => item.position === position);
    const value = direction ? direction.value : 0;
    return total > 0 ? ((value / total) * 100).toFixed(2) : 0;
  };

  return (
    <div className="human-container">
      <div className="direction front">Front : {getPercentage("Front")}%</div>
      <div className="direction front-right">
        Front-Right : {getPercentage("Front-Right")}%
      </div>
      <div className="direction back-right">
        Back-Right : {getPercentage("Back-Right")}%
      </div>
      <div className="direction back">Back : {getPercentage("Back")}%</div>
      <div className="direction back-left">
        Back-Left : {getPercentage("Back-Left")}%
      </div>
      <div className="direction front-left">
        Front-Left : {getPercentage("Front-Left")}%
      </div>

      <img src={headsetOrientation} alt="Top View Human" className="human" />
    </div>
  );
};

export default HumanDirections;
