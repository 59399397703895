import React, { useEffect, useState } from "react";
import "./reports.scss";
import { TbReportSearch } from "react-icons/tb";
import { FaRankingStar } from "react-icons/fa6";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";

const Reports = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [passPercentage, setPassPercentage] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [highScoringStudents, setHighScoringStudents] = useState([]);
  const [textColor, setTextColor] = useState("#f47460");

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/assignment-percentage?collegeId=${currentUser.collegeId}`
        );
        if (
          response.data &&
          response.data.PassPercentage &&
          response.data.remarks
        ) {
          const percentage = parseFloat(response.data.PassPercentage);
          const fetchedRemarks = response.data.remarks;

          setPassPercentage(Math.round(percentage));
          setRemarks(fetchedRemarks);

          switch (fetchedRemarks.toLowerCase()) {
            case "low":
              setTextColor("#ff0000");
              break;
            case "moderate":
              setTextColor("#ffa500");
              break;
            case "high":
              setTextColor("#008000");
              break;
            default:
              setTextColor("#f47460");
          }
        } else {
          setPassPercentage(0);
          setRemarks("!!");
        }
      } catch (error) {
        console.error("Unable to fetch data!", error.message);
        setPassPercentage(0);
        setRemarks("Error fetching data");
      }
    };
    fetchReport();
  }, [currentUser]);

  useEffect(() => {
    const fetchHighScorers = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/high-scoring-students?collegeId=${currentUser.collegeId}`
        );
        setHighScoringStudents(response.data);
      } catch (error) {
        console.error("Unable to fetch data!", error.message);
      }
    };
    fetchHighScorers();
  }, [currentUser]);

  return (
    <div className="reports-wrapper">
      <div className="reports-top">
        <div className="reports-heading">
          <h1>
            <TbReportSearch /> Reports
          </h1>
        </div>
        <div className="progress-info">
          <div className="progress-container">
            <CircularProgressbar
              strokeWidth={17.5}
              text={`${passPercentage}%`}
              value={passPercentage}
              styles={buildStyles({
                textSize: "16px",
                pathColor: `#8c52ff`,
                textColor: textColor,
                trailColor: "#d6d5d5",
                backgroundColor: "#3e98c7",
                pathTransition: "stroke-dashoffset 0.5s ease 0s",
                pathShadow: "0px 0px 6px rgba(140, 82, 255, 0.6)",
                trailShadow: "inset 0px 0px 6px rgba(0, 0, 0, 0.3)",
                dropShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              })}
            />
          </div>
          <p>
            Pass Percentage is:{" "}
            <span className={`remarks-${remarks.toLowerCase()}`}>
              {remarks}
            </span>
          </p>
        </div>
      </div>
      <div className="reports-bottom">
        <p>
          <FaRankingStar className="icon" />
          Top Ranks
        </p>
        {highScoringStudents.length > 0 ? (
          <span>
            {highScoringStudents[0].totalStudents} students got top rank in{" "}
            {highScoringStudents[0].course}
          </span>
        ) : (
          <span>Loading top ranks...</span>
        )}
      </div>
    </div>
  );
};

export default Reports;
