import React from "react";
import "./totalclasses.scss";
import { SiGoogleclassroom } from "react-icons/si";

const TotalClasses = ({ totalClass }) => {
  return (
    <div className="total-classes">
      <div className="left">
        <SiGoogleclassroom className="icon" />
        <h1>{totalClass}</h1>
      </div>
      <div className="right">
        <h1>
          Total
          <br />
          Class
        </h1>
      </div>
    </div>
  );
};

export default TotalClasses;
