import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const colors = ["#8884d8", "#82ca9d", "#ffc658", "#ff6f61", "#d8a1ff"];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#000",
          color: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderRadius: "20px",
          border: "1px solid #ccc",
          padding: "15px",
        }}
      >
        <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
        <p
          style={{ margin: 0 }}
        >{`Average Learning Points Completed: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        style={{
          filter: "drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5))",
        }}
      />
      <polygon
        points={`${x},${y} ${x + width},${y} ${x + width - 8},${y - 8} ${
          x + 8
        },${y - 8}`}
        fill={fill}
        fillOpacity="0.7"
      />
      <polygon
        points={`${x + width},${y} ${x + width - 8},${y - 8} ${x + width - 8},${
          y + height - 8
        } ${x + width},${y + height}`}
        fill={fill}
        fillOpacity="0.5"
      />
    </g>
  );
};

const LearningResourceScatterChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={320}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="chapter"
          tickMargin={10}
          label={{
            value: "Chapter Name",
            position: "insideBottom",
            offset: -15,
            textAnchor: "middle",
          }}
        />
        <YAxis
          label={{
            value: "Average Learning Points Completed",
            angle: -90,
            position: "insideLeft",
            offset: 0,
            style: {
              textAnchor: "middle",
            },
          }}
          domain={[0, "auto"]}
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="totalLearningPointsCompleted"
          name="Learning Points Completed"
          shape={<CustomBar />}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default LearningResourceScatterChart;
