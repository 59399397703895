import React, { useEffect, useState } from "react";
import "./studentprogress.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import ProgressData from "./ProgressData";
import { RiProgress6Line } from "react-icons/ri";
import { useSelector } from "react-redux";

const StudentProgress = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/list/progress?collegeId=${currentUser.collegeId}`
        );
        setData(response.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser]);

  return (
    <div className="student-progress-container">
      <h1>
        <RiProgress6Line />
        Student progress overview
      </h1>
      <div className="stu-prog">
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        {data.length > 0 ? (
          data.map((item, index) => <ProgressData data={item} key={index} />)
        ) : (
          <p>No data is available!</p>
        )}
      </div>
    </div>
  );
};

export default StudentProgress;
