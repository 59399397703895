import React, { useState, useEffect } from "react";
import "./UserAnalyticsComponentDummyData.scss";
import axios from "axios";
import { SERVER_URL } from "../../utils/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UserAnalyticsComponentDummyData = () => {
  const [emails, setEmails] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedCourse, setSelectedCourse] = useState({ id: "", name: "" });
  const [selectedChapter, setSelectedChapter] = useState({ id: "", name: "" });
  const [selectedLearningPoint, setSelectedLearningPoint] = useState({
    id: "",
    name: "",
  });
  const [userAnalytics, setUserAnalytics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [courseStartDate, setCourseStartDate] = useState(new Date());
  const [learningPointStartDate, setLearningPointStartDate] = useState(
    new Date()
  );
  const [learningPointCompletedData, setLearningPointCompletedData] = useState({
    eyeContact: 0,
    posture: 0,
    handGesture: 0,
    reactionTime: 0,
    movementDynamics: 0,
  });
  const [assignmentStartDate, setAssignmentStartDate] = useState(new Date());
  const [assignmentCompleteDate, setAssignmentCompleteDate] = useState(
    new Date()
  );
  const [isAssignment, setIsAssignment] = useState(false);
  const [assignmentPercentage, setAssignmentPercentage] = useState(0);
  const [chapterCompletedDate, setChapterCompletedDate] = useState(new Date());
  const [courseCompletedDate, setCourseCompletedDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const emailsResponse = await axios.get(
          `${SERVER_URL}/user-analytics/emails`
        );
        setEmails(emailsResponse.data);
      } catch (error) {
        console.error("Error fetching emails", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        if (selectedEmail) {
          const coursesResponse = await axios.get(
            `${SERVER_URL}/user-analytics/get/${selectedEmail}`
          );
          setCourses(coursesResponse.data.course || []);
          setSelectedCourse({ id: "", name: "" });
          setSelectedChapter({ id: "", name: "" });
        }
      } catch (error) {
        console.error("Error fetching courses", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCourses();
  }, [selectedEmail]);

  useEffect(() => {
    const fetchUserAnalytics = async () => {
      setLoading(true);
      try {
        if (selectedEmail) {
          const response = await axios.get(
            `${SERVER_URL}/user-analytics/get/${selectedEmail}`
          );
          setUserAnalytics(response.data);
        } else {
          setUserAnalytics(null);
        }
      } catch (error) {
        console.error("Error fetching user analytics", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserAnalytics();
  }, [selectedEmail]);

  const handleCourseSelect = (courseId, courseName) => {
    setSelectedCourse({ id: courseId, name: courseName });
    setSelectedChapter({ id: "", name: "" });
    setSelectedLearningPoint({ id: "", name: "" });
  };

  const handleChapterSelect = (chapterId, chapterName) => {
    setSelectedChapter({ id: chapterId, name: chapterName });
    setSelectedLearningPoint({ id: "", name: "" });
  };

  const handleLearningPointSelect = (learningPointId, learningPointName) => {
    setSelectedLearningPoint({ id: learningPointId, name: learningPointName });
  };

  const handleCourseStartSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/course-started/add/${selectedEmail}/${selectedCourse.id}`,
        {
          triggerTime: courseStartDate,
        }
      );
      alert("Course started date saved successfully!");
    } catch (error) {
      console.error("Error saving course start date", error);
      alert("Failed to save course start date");
    }
  };

  const handleChapterStartSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/chapter-started/add/${selectedEmail}/${selectedCourse.id}/${selectedChapter.id}`,
        {
          triggerTime: courseStartDate,
        }
      );
      alert("Chapter started date saved successfully!");
    } catch (error) {
      console.error("Error saving chapter start date", error);
      alert("Failed to save chapter start date");
    }
  };

  const handleLearningPointStartSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/learning-point-started/add/${selectedEmail}/${selectedCourse.id}/${selectedChapter.id}/${selectedLearningPoint.id}`,
        {
          triggerTime: learningPointStartDate,
        }
      );
      alert("Learning point started date saved successfully!");
    } catch (error) {
      console.error("Error saving learning point start date", error);
      alert("Failed to save learning point start date");
    }
  };

  const handleLearningPointCompletedSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/learning-point-completed/add/${selectedEmail}/${selectedCourse.id}/${selectedChapter.id}/${selectedLearningPoint.id}`,
        {
          triggerTime: learningPointStartDate,
          ...learningPointCompletedData,
        }
      );
      alert("Learning point completed data saved successfully!");
    } catch (error) {
      console.error("Error saving learning point completed data", error);
      alert("Failed to save learning point completed data");
    }
  };

  const handleAssignmentStartSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/assignment-started/add/${selectedEmail}/${selectedCourse.id}/${selectedChapter.id}`,
        {
          triggerTime: assignmentStartDate,
        }
      );
      alert("Assignment started date saved successfully!");
    } catch (error) {
      console.error("Error saving assignment start date", error);
      alert("Failed to save assignment start date");
    }
  };

  const handleAssignmentCompleteSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/assignment-completed/add/${selectedEmail}/${selectedCourse.id}/${selectedChapter.id}`,
        {
          triggerTime: assignmentCompleteDate,
          isAssignment,
          assignmentPercentage,
        }
      );
      alert("Assignment completed date saved successfully!");
    } catch (error) {
      console.error("Error saving assignment completed date", error);
      alert("Failed to save assignment completed date");
    }
  };

  const handleChapterCompletedSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/chapter-completed/add/${selectedEmail}/${selectedCourse.id}/${selectedChapter.id}`,
        {
          triggerTime: chapterCompletedDate,
        }
      );
      alert("Chapter completed saved successfully!");
    } catch (error) {
      console.error("Error saving chapter completed", error);
      alert("Failed to save chapter completed");
    }
  };

  const handleCourseCompletedSave = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${SERVER_URL}/user-analytics/course-completed/add/${selectedEmail}/${selectedCourse.id}`,
        {
          triggerTime: courseCompletedDate,
        }
      );
      alert("Course completed saved successfully!");
    } catch (error) {
      console.error("Error saving course completed", error);
      alert("Failed to save course completed");
    }
  };

  const renderChapters = () => {
    if (!selectedCourse.id || !courses.length || !userAnalytics) {
      return null;
    }

    const selectedCourseData = courses.find(
      (course) => course._id === selectedCourse.id
    );

    if (!selectedCourseData || !selectedCourseData.chapter) {
      return null;
    }

    return selectedCourseData.chapter.map((chapter, index) => (
      <option key={index} value={chapter._id}>
        {chapter.chapter}
      </option>
    ));
  };

  const renderLearningPoints = () => {
    if (!selectedChapter.id || !courses.length || !userAnalytics) {
      return null;
    }

    const selectedCourseData = courses.find(
      (course) => course._id === selectedCourse.id
    );

    const selectedChapterData = selectedCourseData.chapter.find(
      (chapter) => chapter._id === selectedChapter.id
    );

    if (!selectedChapterData || !selectedChapterData.learningPoints) {
      return null;
    }

    return selectedChapterData.learningPoints.map((learningPoint, index) => (
      <option key={index} value={learningPoint._id}>
        {learningPoint.learningPointName}
      </option>
    ));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-analytics">
      <div className="selection">
        <select
          value={selectedEmail}
          onChange={(e) => {
            setSelectedEmail(e.target.value);
            setSelectedCourse({ id: "", name: "" });
            setSelectedChapter({ id: "", name: "" });
          }}
        >
          <option value="">Select Email</option>
          {emails.map((email, index) => (
            <option key={`email-${index}`} value={email}>
              {email}
            </option>
          ))}
        </select>
        <select
          value={selectedCourse.name}
          onChange={(e) => {
            const selected = courses.find(
              (course) => course.course === e.target.value
            );
            handleCourseSelect(selected._id, selected.course);
          }}
        >
          <option value="">Select Course</option>
          {courses.map((course, index) => (
            <option key={`course-${index}`} value={course.course}>
              {course.course}
            </option>
          ))}
        </select>
        <select
          value={selectedChapter.name}
          onChange={(e) => {
            const selectedCourseData = courses.find(
              (course) => course._id === selectedCourse.id
            );
            const selected = selectedCourseData.chapter.find(
              (chapter) => chapter._id === e.target.value
            );
            handleChapterSelect(selected._id, selected.chapter);
          }}
        >
          <option value="">Select Chapter</option>
          {renderChapters()}
        </select>
        <select
          value={selectedLearningPoint.name}
          onChange={(e) => {
            const selectedCourseData = courses.find(
              (course) => course._id === selectedCourse.id
            );
            const selectedChapterData = selectedCourseData.chapter.find(
              (chapter) => chapter._id === selectedChapter.id
            );
            const selected = selectedChapterData.learningPoints.find(
              (learningPoint) => learningPoint._id === e.target.value
            );
            handleLearningPointSelect(selected._id, selected.learningPointName);
          }}
        >
          <option value="">Select Learning Point</option>
          {renderLearningPoints()}
        </select>
      </div>

      {selectedCourse.id && (
        <div className="details">
          <div className="section">
            <h3>Course Started for {selectedCourse.name}</h3>
            <form className="course-started" method="POST">
              <DatePicker
                selected={courseStartDate}
                onChange={(date) => setCourseStartDate(date)}
              />
              <button onClick={handleCourseStartSave}>Save</button>
            </form>
          </div>

          <div className="section">
            <h3>
              Chapter Started for {selectedCourse.name} - Chapter{" "}
              {selectedChapter.name}
            </h3>
            <form className="chapter-started" method="POST">
              <DatePicker
                selected={courseStartDate}
                onChange={(date) => setCourseStartDate(date)}
              />
              <button onClick={handleChapterStartSave}>Save</button>
            </form>
          </div>

          <div className="section">
            <h3>
              Learning Point Started for {selectedCourse.name} - Chapter{" "}
              {selectedChapter.name} - Learning Point{" "}
              {selectedLearningPoint.name}
            </h3>
            <form className="learning-point-started" method="POST">
              <DatePicker
                selected={learningPointStartDate}
                onChange={(date) => setLearningPointStartDate(date)}
              />
              <button onClick={handleLearningPointStartSave}>Save</button>
            </form>
          </div>

          <div className="section">
            <h3>
              Learning Point Completed for {selectedCourse.name} - Chapter{" "}
              {selectedChapter.name} - Learning Point{" "}
              {selectedLearningPoint.name}
            </h3>
            <form className="learning-point-completed" method="POST">
              <DatePicker
                selected={learningPointStartDate}
                onChange={(date) => setLearningPointStartDate(date)}
              />
              <input
                type="number"
                value={learningPointCompletedData.eyeContact}
                onChange={(e) =>
                  setLearningPointCompletedData({
                    ...learningPointCompletedData,
                    eyeContact: parseFloat(e.target.value),
                  })
                }
                placeholder="Eye Contact"
              />
              <input
                type="number"
                value={learningPointCompletedData.posture}
                onChange={(e) =>
                  setLearningPointCompletedData({
                    ...learningPointCompletedData,
                    posture: parseFloat(e.target.value),
                  })
                }
                placeholder="Posture"
              />
              <input
                type="number"
                value={learningPointCompletedData.handGesture}
                onChange={(e) =>
                  setLearningPointCompletedData({
                    ...learningPointCompletedData,
                    handGesture: parseFloat(e.target.value),
                  })
                }
                placeholder="Hand Gesture"
              />
              <input
                type="number"
                value={learningPointCompletedData.reactionTime}
                onChange={(e) =>
                  setLearningPointCompletedData({
                    ...learningPointCompletedData,
                    reactionTime: parseFloat(e.target.value),
                  })
                }
                placeholder="Reaction Time"
              />
              <input
                type="number"
                value={learningPointCompletedData.movementDynamics}
                onChange={(e) =>
                  setLearningPointCompletedData({
                    ...learningPointCompletedData,
                    movementDynamics: parseFloat(e.target.value),
                  })
                }
                placeholder="Movement Dynamics"
              />
              <button onClick={handleLearningPointCompletedSave}>Save</button>
            </form>
          </div>

          <div className="section">
            <h3>
              Assignment Started for {selectedCourse.name} - Chapter{" "}
              {selectedChapter.name}
            </h3>
            <form className="assignment-started" method="POST">
              <DatePicker
                selected={assignmentStartDate}
                onChange={(date) => setAssignmentStartDate(date)}
              />
              <button onClick={handleAssignmentStartSave}>Save</button>
            </form>
          </div>

          <div className="section">
            <h3>
              Assignment Completed for {selectedCourse.name} - Chapter{" "}
              {selectedChapter.name}
            </h3>
            <form className="assignment-completed" method="POST">
              <DatePicker
                selected={assignmentCompleteDate}
                onChange={(date) => setAssignmentCompleteDate(date)}
              />
              <input
                type="checkbox"
                value={isAssignment}
                onChange={(e) => setIsAssignment(e.target.checked)}
              />
              <input
                type="number"
                value={assignmentPercentage}
                onChange={(e) => setAssignmentPercentage(e.target.value)}
              />
              <button onClick={handleAssignmentCompleteSave}>Save</button>
            </form>
          </div>

          <div className="section">
            <h3>
              Chapter Completed for {selectedCourse.name} - Chapter{" "}
              {selectedChapter.name}
            </h3>
            <form className="chapter-completed" method="POST">
              <DatePicker
                selected={chapterCompletedDate}
                onChange={(date) => setChapterCompletedDate(date)}
              />
              <button onClick={handleChapterCompletedSave}>Save</button>
            </form>
          </div>

          <div className="section">
            <h3>
              Course Completed for {selectedCourse.name} - Chapter{" "}
              {selectedChapter.name}
            </h3>
            <form className="course-completed" method="POST">
              <DatePicker
                selected={courseCompletedDate}
                onChange={(date) => setCourseCompletedDate(date)}
              />
              <button onClick={handleCourseCompletedSave}>Save</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAnalyticsComponentDummyData;
