import React from "react";
import CourseManagement from "../../pages/content-management/CourseManagement";

const CourseManagementMain = () => {
  return (
    <main id="main" className="main">
      <CourseManagement />
    </main>
  );
};

export default CourseManagementMain;
