import React from "react";
import AllocateContent from "../../pages/allocate-content/AllocateContent";

const ContentAllocationMain = () => {
  return (
    <main id="main" className="main">
      <AllocateContent />
    </main>
  );
};

export default ContentAllocationMain;
