import React, { useEffect, useState } from "react";
import "./cognitionanalysis.scss";
import CognitionRadarChart from "../../components/cognition-analysis/charts/CognitionRadarChart";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import ControllerErgonomics from "../../components/cognition-analysis/ControllerErgonomics";
import HeadsetOrientation from "../../components/cognition-analysis/HeadsetOrientation";
import { useSelector } from "react-redux";

const CognitionAnalysis = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [radarData, setRadarData] = useState([]);
  const [percentages, setPercentages] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [leftCheckboxes, setLeftCheckboxes] = useState([]);
  const [rightCheckboxes, setRightCheckboxes] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [error, setError] = useState("");
  const [email, setEmail] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [courseNames, setCourseNames] = useState([]);
  const [controllerErgonomics, setControllerErgonomics] = useState([]);
  const [headsetOrientation, setHeadsetOrientation] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/admin/students?collegeId=${currentUser.collegeId}`
        );
        setEmail(response.data);
      } catch (error) {
        console.error("Unable to fetch students!", error.message);
      }
    };
    fetchEmails();
  }, [currentUser]);

  useEffect(() => {
    const fetchCourseNames = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/names?collegeId=${currentUser.collegeId}`
        );
        setCourseNames(response.data);
        setLeftCheckboxes(new Array(response.data.length).fill(false));
      } catch (error) {
        console.error("Unable to fetch course names!", error.message);
      }
    };
    fetchCourseNames();
  }, [currentUser]);

  const handleCheckboxChange = (setCheckboxes, index) => {
    setCheckboxes((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleSubmit = async () => {
    const selectedCourses = courseNames
      .filter((_, index) => leftCheckboxes[index])
      .map((course) => course);
    const selectedCognitiveParams = [
      "eyeContact",
      "posture",
      "handGesture",
      "reactionTime",
      "movementDynamics",
    ].filter((_, index) => rightCheckboxes[index]);
    if (selectedCourses.length >= 1 && selectedCognitiveParams.length >= 3) {
      setIsModalOpen(false);
      setError("");
      try {
        const response = await axios.post(
          `${SERVER_URL}/user-analytics/average-cognitive-parameters/${selectedEmail}?collegeId=${currentUser.collegeId}`,
          {
            selectedCourses,
            cognitiveParameters: selectedCognitiveParams,
          }
        );
        setRadarData(response.data);

        const total = response.data.reduce(
          (sum, param) => sum + param["Soft-Skills"],
          0
        );
        const percentageData = response.data.reduce((acc, param) => {
          acc[param.parameters] = (
            (param["Soft-Skills"] / total) *
            100
          ).toFixed(2);
          return acc;
        }, {});
        setPercentages(percentageData);

        const controllerErgonomicsResponse = await axios.get(
          `${SERVER_URL}/user-analytics/hand-gesture-count/${selectedEmail}?collegeId=${currentUser.collegeId}`
        );
        setControllerErgonomics(
          controllerErgonomicsResponse.data.handGestureCounts
        );

        const headsetOrientationResponse = await axios.get(
          `${SERVER_URL}/user-analytics/eye-contact-ranges/${selectedEmail}?collegeId=${currentUser.collegeId}`
        );
        setHeadsetOrientation(headsetOrientationResponse.data.eyeContactCounts);
      } catch (error) {
        console.error("Unable to fetch cognitive averages!", error.message);
        setError("An error occurred while calculating the averages.");
      }
    } else {
      setError(
        "Please select at least one checkbox from the COURSES section and three checkboxes from the COGNITIVE PARAMETERS section."
      );
    }
  };

  const isSelectionValid = () => {
    const selectedLeft = leftCheckboxes.filter((checked) => checked).length;
    const selectedRight = rightCheckboxes.filter((checked) => checked).length;
    return selectedLeft >= 1 && selectedRight >= 3;
  };

  return (
    <div className="cognition-analysis-container">
      {isModalOpen ? (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="form-group">
              <select
                value={selectedEmail}
                onChange={(e) => setSelectedEmail(e.target.value)}
              >
                <option value="" disabled>
                  Select a student
                </option>
                {email.map((emailItem, index) => (
                  <option key={index} value={emailItem.email}>
                    {emailItem.email}
                  </option>
                ))}
              </select>
              <label htmlFor="course">Select Student</label>
            </div>
            <div className="modal-section">
              <div className="left-section">
                <h6>Courses</h6>
                {courseNames.map((course, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={leftCheckboxes[index] || false}
                      onChange={() =>
                        handleCheckboxChange(setLeftCheckboxes, index)
                      }
                    />
                    {course}
                  </label>
                ))}
              </div>
              <div className="right-section">
                <h6>Cognitive Parameters</h6>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[0]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 0)}
                  />
                  Eye Contact
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[1]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 1)}
                  />
                  Posture
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[2]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 2)}
                  />
                  Hand Gesture
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[3]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 3)}
                  />
                  Reaction Time
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[4]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 4)}
                  />
                  Movement Dynamics
                </label>
              </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            <button onClick={handleSubmit} className="submit-button">
              Submit
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="analysis-top">
            <div className="analysis-heading">
              <h1>
                <i className="bi bi-clipboard-data"></i>Cognition Analysis
              </h1>
              <span>Cognition Analysis</span>
            </div>
          </div>
          {isSelectionValid() ? (
            <>
              <p className="stu-info">
                Cognition Analysis for the student:{" "}
                <span className="stu-email">{selectedEmail}</span>
              </p>
              <div className="analysis-main">
                <div className="left-group">
                  <CognitionRadarChart data={radarData} />
                  <div className="info-group">
                    <ul>
                      {rightCheckboxes[0] && (
                        <li>
                          <p>
                            <b>Eye Contact : {percentages["eyeContact"]}%</b>
                            <br />
                            <span>
                              The ability to maintain visual engagement during
                              communication reflects confidence, attentiveness,
                              and connection.
                            </span>
                          </p>
                        </li>
                      )}
                      {rightCheckboxes[1] && (
                        <li>
                          <p>
                            <b>Posture : {percentages["posture"]}%</b>
                            <br />
                            <span>
                              The way one holds their body when sitting or
                              standing can convey confidence, attentiveness, and
                              professionalism.
                            </span>
                          </p>
                        </li>
                      )}
                      {rightCheckboxes[2] && (
                        <li>
                          <p>
                            <b>Hand Gesture : {percentages["handGesture"]}%</b>
                            <br />
                            <span>
                              Hand gestures complement verbal communication,
                              enhancing clarity, emphasizing key points, and
                              making communication more dynamic.
                            </span>
                          </p>
                        </li>
                      )}
                      {rightCheckboxes[3] && (
                        <li>
                          <p>
                            <b>
                              Reaction Time : {percentages["reactionTime"]}%
                            </b>
                            <br />
                            <span>
                              Reaction time reflects attentiveness, mental
                              agility, and engagement in a conversation or task.
                            </span>
                          </p>
                        </li>
                      )}
                      {rightCheckboxes[4] && (
                        <li>
                          <p>
                            <b>
                              Movement Dynamics :{" "}
                              {percentages["movementDynamics"]}%
                            </b>
                            <br />
                            <span>
                              Fluidity and coordination of body movements
                              reflect comfort and confidence during
                              communication or tasks.
                            </span>
                          </p>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="right-group">
                  <ControllerErgonomics data={controllerErgonomics} />
                  <HeadsetOrientation data={headsetOrientation} />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default CognitionAnalysis;
