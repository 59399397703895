import React from "react";
import "./dashboard.scss";
import Reports from "../../components/dashboard/Reports";
import Attendance from "../../components/dashboard/Attendance";
import Info from "../../components/dashboard/Info";
import TopPerformingCourses from "../../components/dashboard/TopPerformingCourses";
import StudentProgress from "../../components/dashboard/StudentProgress";
import CourseEffectivenessAnalysis from "../../components/dashboard/CourseEffectivenessAnalysis";
import CourseAssigned from "../../components/dashboard/CourseAssigned";
import LearningPointsEffectiveness from "../../components/dashboard/LearningPointsEffectiveness";

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="top">
        <div className="top-left">
          <div className="reports-container">
            <Reports />
          </div>
        </div>
        <div className="top-middle">
          <Attendance />
        </div>
        <div className="top-right">
          <Info />
        </div>
      </div>
      <div className="middle">
        <div className="chart">
          <TopPerformingCourses />
        </div>
        <div className="progress-bars">
          <StudentProgress />
        </div>
        <div className="task">
          <CourseAssigned/>
        </div>
      </div>
      <div className="bottom">
        <div className="line-chart">
          <CourseEffectivenessAnalysis />
        </div>
        <div className="pie-chart">
          <LearningPointsEffectiveness />
        </div>
        <div className="chat">

        </div>
      </div>
    </div>
  );
};

export default Dashboard;

// import React from "react";
// import "./professor-dashboard.scss";
// import TotalStudents from "../../components/dashboard/TotalStudents";
// import TotalClasses from "../../components/dashboard/TotalClasses";
// import TotalAssignments from "../../components/dashboard/TotalAssignments";
// import Reports from "../../components/dashboard/Reports";
// import TopPerformingCourses from "../../components/dashboard/TopPerformingCourses";
// import CourseAssigned from "../../components/dashboard/CourseAssigned";
// import CourseEffectivenessAnalysis from "../../components/dashboard/CourseEffectivenessAnalysis";
// import LearningPointsEffectiveness from "../../components/dashboard/LearningPointsEffectiveness";
// import StudentProgress from "../../components/dashboard/StudentProgress";

// const Dashboard = () => {
//   return (
//     <div className="dashboard-container">
//       <div className="dashboard-top">
//         <TotalStudents />
//         <TotalClasses />
//         <TotalAssignments />
//       </div>
//       <div className="dashboard-bottom">
//         <div className="chart-container">
//           <Reports />
//           <TopPerformingCourses />
//         </div>
//         <div className="chart-container">
//           <LearningPointsEffectiveness />
//           <StudentProgress />
//         </div>
//         <div className="chart-container">
//           <CourseAssigned />
//           <CourseEffectivenessAnalysis />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;
