import React, { useState } from "react";
import "./searchbar.scss";

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    onSearch(newSearchTerm);
  };

  return (
    <div className="input-group">
      <form onSubmit={onSearch}>
        <input
          type="search"
          name="query"
          placeholder="Search"
          title="Enter search keyword"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="btn-search" type="submit">
          <i className="bi bi-search" aria-hidden="true"></i>
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
