import React, { useEffect, useState } from "react";
import "./controllerergonomics.scss";
import controllerImage from "../../assets/controller.png";

const ControllerErgonomics = ({ data }) => {
  const [shape1, setShape1] = useState(0);
  const [shape2, setShape2] = useState(0);
  const [shape3, setShape3] = useState(0);

  useEffect(() => {
    if (data) {
      const total = Object.values(data).reduce((acc, value) => acc + value, 0);

      if (total > 0) {
        setShape1((((data["0-30"] || 0) / total) * 100).toFixed(2));
        setShape2((((data["31-120"] || 0) / total) * 100).toFixed(2));
        setShape3((((data["121-180"] || 0) / total) * 100).toFixed(2));
      } else {
        setShape1(0);
        setShape2(0);
        setShape3(0);
      }
    }
  }, [data]);

  return (
    <div className="controller-ergonomics-controller">
      <h6>Controller Ergonomics</h6>
      <div className="ergonomics-details">
        <div className="controller-details">
          <img src={controllerImage} alt="controller" />
        </div>
        <div className="shape-info">
          <div className="rectangle">
            <div className="shape1"></div>
            <div className="shape1-value">above shoulder</div>
          </div>
          <div className="rectangle">
            <div className="shape2"></div>
            <div className="shape2-value">Chest level</div>
          </div>
          <div className="rectangle">
            <div className="shape3"></div>
            <div className="shape3-value">below waist</div>
          </div>
        </div>
      </div>
      <div className="details-value">
        <div className="rectangle">
          <div className="shape1"></div>
          <div className="shape1-value">{shape1}%</div>
        </div>
        <div className="rectangle">
          <div className="shape2"></div>
          <div className="shape2-value">{shape2}%</div>
        </div>
        <div className="rectangle">
          <div className="shape3"></div>
          <div className="shape3-value">{shape3}%</div>
        </div>
      </div>
    </div>
  );
};

export default ControllerErgonomics;
