import React from 'react'
import UserAnalyticsComponentDummyData from '../../pages/dummy_data/UserAnalyticsComponentDummyData';

const DummyDataMain = () => {
  return (
    <main id="main" className="main">
      <UserAnalyticsComponentDummyData />
    </main>
  );
}

export default DummyDataMain