import React, { useState } from "react";
import "./swipper-popup.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SwipperPopUp = ({ courseData, onChaptersSelected, loading }) => {
  const [selectedChapters, setSelectedChapters] = useState([]);

  const handleChapterSelect = (courseIndex, chapterIndex) => {
    const chapterId = `${courseIndex}-${chapterIndex}`;
    const isSelected = selectedChapters.includes(chapterId);

    if (isSelected) {
      setSelectedChapters(selectedChapters.filter((id) => id !== chapterId));
    } else {
      setSelectedChapters([...selectedChapters, chapterId]);
    }
  };

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-prev-arrow`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow custom-next-arrow`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  };

  const sliderSettings = {
    dots: true,
    infinite: courseData.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    style: { width: "100%" },
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: courseData.length > 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const handleSave = () => {
    const selectedData = courseData.map((course, courseIndex) => ({
      course: course.course,
      chapter: course.chapters.filter((_, chapterIndex) =>
        selectedChapters.includes(`${courseIndex}-${chapterIndex}`)
      ),
    }));
    onChaptersSelected(selectedData);
  };

  return (
    <div className="slider-popup">
      <Slider {...sliderSettings}>
        {courseData.map((course, courseIndex) => (
          <div key={courseIndex} className="slide">
            <h3>{course.course}</h3>
            {course.chapters.map((chapter, chapterIndex) => (
              <div key={chapterIndex} className="chapter-item">
                <input
                  type="checkbox"
                  checked={selectedChapters.includes(
                    `${courseIndex}-${chapterIndex}`
                  )}
                  onChange={() =>
                    handleChapterSelect(courseIndex, chapterIndex)
                  }
                  id={`chapter-${courseIndex}-${chapterIndex}`}
                />
                <label htmlFor={`chapter-${courseIndex}-${chapterIndex}`}>
                  {chapter.chapter}
                </label>
              </div>
            ))}
          </div>
        ))}
      </Slider>
      <div className="allocate-form-actions">
        <button
          type="button"
          className="allocate-form-button"
          onClick={handleSave}
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default SwipperPopUp;
