import React from "react";
import "./cognitionradarchart.scss";
import { ResponsiveRadar } from "@nivo/radar";

const CognitionRadarChart = ({ data }) => {
  if (!data || data.length === 0) return <div>No data available</div>;

  const transformedData = data.map((item) => {
    const { parameters, ...values } = item;
    return { ...values, parameters };
  });

  const keys = Object.keys(transformedData[0]).filter(
    (key) => key !== "parameters"
  );

  return (
    <div className="radar-chart-container">
      <ResponsiveRadar
        data={transformedData}
        keys={keys}
        indexBy="parameters"
        valueFormat=">-.2f"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        borderColor={{ from: "color" }}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={{ theme: "background" }}
        dotBorderWidth={2}
        colors={{ scheme: "nivo" }}
        blendMode="multiply"
        motionConfig="wobbly"
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            translateX: -50,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: "#999",
            symbolSize: 12,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default CognitionRadarChart;
