// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group form {
  background: gray;
  border-radius: 25px;
  position: relative;
  height: 40px;
}
.input-group form input {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 25px;
  font-size: 16px;
  padding: 8px 40px 8px 20px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.input-group form input:focus {
  outline: none;
}
.input-group form .btn-search {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 50px;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
}
.input-group form .btn-search i {
  color: #000;
}
.input-group form .btn-search i:hover {
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/searchbar/searchbar.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;EACA,0BAAA;EACA,YAAA;EACA,4CAAA;AAAN;AAEM;EACE,aAAA;AAAR;AAII;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AAFN;AAIM;EACE,WAAA;AAFR;AAIQ;EACE,YAAA;AAFV","sourcesContent":[".input-group {\n  form {\n    background: gray;\n    border-radius: 25px;\n    position: relative;\n    height: 40px;\n\n    input {\n      width: 100%;\n      height: 100%;\n      display: block;\n      border-radius: 25px;\n      font-size: 16px;\n      padding: 8px 40px 8px 20px;\n      border: none;\n      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n\n      &:focus {\n        outline: none;\n      }\n    }\n\n    .btn-search {\n      position: absolute;\n      top: 0px;\n      right: 0px;\n      width: 50px;\n      height: 100%;\n      border-radius: 50%;\n      cursor: pointer;\n      border: none;\n      background: none;\n      font-size: 16px;\n\n      i {\n        color: #000;\n\n        &:hover {\n          opacity: 0.7;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
