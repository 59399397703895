import React from 'react';
import "./totalstudents.scss";
import { FaUsers } from "react-icons/fa6";

const TotalStudents = ({totalStudents}) => {
  return (
    <div className="total-students">
      <div className="left">
        <FaUsers className="icon" />
        <h1>{totalStudents}</h1>
      </div>
      <div className="right">
        <h1>Total<br/>Students</h1>
      </div>
    </div>
  );
}

export default TotalStudents
