import React from "react";
import "./totalassignments.scss";
import { MdOutlineAssignment } from "react-icons/md";

const TotalAssignments = ({ totalAssignments }) => {
  return (
    <div className="total-assignments">
      <div className="left">
        <MdOutlineAssignment className="icon" />
        <h1>{totalAssignments}</h1>
      </div>
      <div className="right">
        <h1>
          Total
          <br />
          Assignments
        </h1>
      </div>
    </div>
  );
};

export default TotalAssignments;
