import React, { useEffect, useState } from "react";
import "./info.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import TotalClasses from "./info/TotalClasses";
import TotalStudents from "./info/TotalStudents";
import TotalAssignments from "./info/TotalAssignments";
import { useSelector } from "react-redux";

const Info = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [totalClass, setTotalClass] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalAssignments, setTotalAssignments] = useState(0); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const [studentsResponse, chaptersResponse, coursesResponse] =
          await Promise.all([
            axios.get(
              `${SERVER_URL}/admin/count/students?collegeId=${currentUser.collegeId}`
            ),
            axios.get(
              `${SERVER_URL}/user-analytics/count/total-chapters?collegeId=${currentUser.collegeId}`
            ),
            axios.get(
              `${SERVER_URL}/course/total-chapters?collegeId=${currentUser.collegeId}`
            ),
          ]);

        const { totalStudents } = studentsResponse.data;
        const { totalChapters: totalChapterFromAnalytics } =
          chaptersResponse.data;
        const { totalChapters: totalChapterFromCourses } = coursesResponse.data;

        setTotalStudents(totalStudents);
        setTotalClass(totalChapterFromAnalytics); 
        setTotalAssignments(totalChapterFromCourses); 
      } catch (error) {
        setError(`Error loading data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  return (
    <div className="info">
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      <TotalStudents totalStudents={totalStudents} />
      <TotalClasses totalClass={totalClass} />
      <TotalAssignments totalAssignments={totalAssignments} />
    </div>
  );
};

export default Info;
