import React from "react";
import "./main.scss";
import Dashboard from "../../pages/dashboard/Dashboard";

const Main = () => {
  return (
    <main id="main" className="main">
      <Dashboard />
    </main>
  );
};

export default Main;
