import React, { useState } from "react";
import "./courseinfo.scss";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const CourseInfo = ({ data, refreshData }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [newHeading, setNewHeading] = useState("");
  const [newDescription, setNewDescription] = useState("");

  const handleAddClick = () => {
    setModalMode("add");
    setNewHeading("");
    setNewDescription("");
    setIsModalOpen(true);
  };

  const handleEditClick = () => {
    if (
      data.courseInfo &&
      data.courseInfo.heading &&
      data.courseInfo.description
    ) {
      setModalMode("edit");
      setNewHeading(data.courseInfo.heading);
      setNewDescription(data.courseInfo.description);
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = () => {
    if (
      data.courseInfo &&
      data.courseInfo.heading &&
      data.courseInfo.description
    ) {
      setIsDeleteConfirmOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewHeading("");
    setNewDescription("");
  };

  const handleCloseDeleteConfirm = () => {
    setIsDeleteConfirmOpen(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        heading: newHeading,
        description: newDescription,
      };

      let response;
      if (modalMode === "add") {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/course-info/add?collegeId=${currentUser.collegeId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        response = await axios.put(
          `${SERVER_URL}/course/${data._id}/course-info/update?collegeId=${currentUser.collegeId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(
        `${SERVER_URL}/course/${data._id}/course-info/delete?collegeId=${currentUser.collegeId}`
      );
      if (response.status === 200) {
        console.log("Delete confirmed");
        refreshData();
      }
      handleCloseDeleteConfirm();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <div className="intro">
        <h1>
          <i className="bi bi-info-square"></i> Course Info
        </h1>
      </div>
      <div className="content">
        <p>
          <b>Heading:</b>{" "}
          {data.courseInfo && data.courseInfo.heading
            ? data.courseInfo.heading
            : "No heading is available"}
        </p>
        <p>
          <b>Description:</b>{" "}
          {data.courseInfo && data.courseInfo.description
            ? data.courseInfo.description.substring(0, 150) + "..."
            : "No description is available"}
        </p>
      </div>
      <div className="button-wrapper">
        <div
          className={`button ${
            data.courseInfo &&
            data.courseInfo.heading &&
            data.courseInfo.description
              ? "disabled"
              : ""
          }`}
          onClick={
            data.courseInfo &&
            data.courseInfo.heading &&
            data.courseInfo.description
              ? null
              : handleAddClick
          }
        >
          <div className="icon">
            <i className="bi bi-plus-lg"></i>
          </div>
          <span>Add Course Info</span>
        </div>
        <div className="button" onClick={handleEditClick}>
          <div className="icon">
            <i className="bi bi-pencil-square"></i>
          </div>
          <span>Edit Course Info</span>
        </div>
        <div className="button" onClick={handleDeleteClick}>
          <div className="icon">
            <i className="bi bi-trash"></i>
          </div>
          <span>Delete Course Info</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>
              {modalMode === "add" ? "Add Course Info" : "Edit Course Info"}
            </h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="new-heading">Heading:</label>
                <input
                  type="text"
                  id="new-heading"
                  value={newHeading}
                  onChange={(e) => setNewHeading(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="new-description">Description:</label>
                <textarea
                  id="new-description"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  required
                />
              </div>
              <button type="submit">
                {modalMode === "add" ? "Add" : "Update"}
              </button>
            </form>
          </div>
        </div>
      )}

      {isDeleteConfirmOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseDeleteConfirm}>
              &times;
            </span>
            <h1>Delete Course Info</h1>
            <p>Are you sure you want to delete this item?</p>
            <div className="delete-buttons">
              <button onClick={handleDeleteConfirm}>Yes</button>
              <button onClick={handleCloseDeleteConfirm}>No</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseInfo;
