import React from "react";
import "./deleteconfirmationdialog.scss";

const DeleteConfirmationDialog = ({ onCancel, onConfirm }) => {
  return (
    <div className="delete-confirmation-dialog">
      <p>Are you sure you want to delete this course?</p>
      <div className="buttons">
        <button onClick={onCancel}>Cancel</button>
        <button onClick={onConfirm}>Delete</button>
      </div>
    </div>
  );
};

export default DeleteConfirmationDialog;
