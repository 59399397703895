// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-progress-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: max-content;
  height: 100%;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.student-progress-container::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(90deg, #642bff, #ff22e6);
  border-radius: 22px;
  z-index: -1;
}
.student-progress-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 20px;
  z-index: -1;
}
.student-progress-container h1 {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  font-size: 19px;
  font-weight: 700;
  color: #000;
  position: sticky;
  top: 0;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 0;
  padding: 20px;
  z-index: 10;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
}
.student-progress-container .stu-prog {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: 10px;
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/studentprogress.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,4CAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,oDAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAEE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,MAAA;EACA,gBAAA;EACA,4BAAA;EACA,6BAAA;EACA,SAAA;EACA,aAAA;EACA,WAAA;EACA,WAAA;EACA,0CAAA;AADJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;AAFJ","sourcesContent":[".student-progress-container {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: max-content;\n  height: 100%;\n  background: #fff;\n  border-radius: 20px;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n\n  &::before {\n    content: \"\";\n    position: absolute;\n    top: -2px;\n    left: -2px;\n    right: -2px;\n    bottom: -2px;\n    background: linear-gradient(90deg, #642bff, #ff22e6);\n    border-radius: 22px;\n    z-index: -1;\n  }\n\n  &::after {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: white;\n    border-radius: 20px;\n    z-index: -1;\n  }\n\n  h1 {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    text-transform: uppercase;\n    font-size: 19px;\n    font-weight: 700;\n    color: #000;\n    position: sticky;\n    top: 0;\n    background: #fff;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;\n    margin: 0;\n    padding: 20px;\n    z-index: 10;\n    width: 100%;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;\n  }\n\n  .stu-prog {\n    display: flex;\n    flex-direction: column;\n    overflow-y: auto;\n    border-radius: 10px;\n    margin: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
