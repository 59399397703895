import React from "react";
import CourseDetailsPage from "../../pages/content-management/CourseDetailsPage";

const CourseDetailsMain = () => {
  return (
    <main id="main" className="main">
      <CourseDetailsPage />
    </main>
  );
};

export default CourseDetailsMain;
