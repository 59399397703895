import React, { useEffect, useState } from "react";
import "./attendance.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { BiSelectMultiple } from "react-icons/bi";
import AttendanceBarChart from "./charts/AttendanceBarChart";
import { useSelector } from "react-redux";

const Attendance = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [barChartData, setBarChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBarChartData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/attendance/last7days?collegeId=${currentUser.collegeId}`
        );
        setBarChartData(response.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchBarChartData();
  }, [currentUser]);

  return (
    <div className="attendance-wrapper">
      <h1>
        <BiSelectMultiple className="icon" />
        Attendance across the courses
      </h1>
      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
      {barChartData.length > 0 ? (
        <AttendanceBarChart data={barChartData} />
      ) : (
        <p>No data is available!</p>
      )}
    </div>
  );
};

export default Attendance;
