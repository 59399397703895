import React, { useState } from "react";
import "./chapter.scss";
import ChapterDetails from "./ChapterDetails";
import { SERVER_URL } from "../../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const Chapter = ({ data, refreshData }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chapterName, setChapterName] = useState("");
  const [chapterDownloadUrl, setChapterDownloadUrl] = useState("");

  const handleAddChapter = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setChapterName("");
    setChapterDownloadUrl("");
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("chapter", chapterName);
      formData.append("chapterDownloadLink", chapterDownloadUrl);

      const response = await axios.put(
        `${SERVER_URL}/course/${data._id}/chapter/add?collegeId=${currentUser.collegeId}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        handleCloseModal();
        refreshData();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="chapter-details">
      <div className="intro">
        <h1>
          <i className="bi bi-journal-text"></i> Chapter
        </h1>
        <div className="button" onClick={handleAddChapter}>
          <div className="icon">
            <i className="bi bi-plus-lg"></i>
          </div>
          <span>Add Chapter</span>
        </div>
      </div>
      <div className="ch-info">
        {data.chapters &&
          data.chapters.map((ch, index) => (
            <ChapterDetails
              key={index}
              courseId={data._id}
              data={ch}
              refreshData={refreshData}
              chapterData={data}
            />
          ))}
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <h1>Add Chapter</h1>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="chapterName">Chapter Name:</label>
                <input
                  type="text"
                  id="chapterName"
                  value={chapterName}
                  onChange={(e) => setChapterName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="chapterDownloadUrl">Download URL:</label>
                <input
                  type="text"
                  id="chapterDownloadUrl"
                  value={chapterDownloadUrl}
                  onChange={(e) => setChapterDownloadUrl(e.target.value)}
                  required
                />
              </div>
              <button type="submit">Add</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chapter;
