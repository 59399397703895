import React from "react";
import "./navmessages.scss";
import { NavLink } from "react-router-dom";

const NavMessages = () => {
  return (
    <li className="nav-item dropdown">
      <NavLink className="nav-link nav-icon" to="" data-bs-toggle="dropdown">
        <i className="bi bi-chat-left-text"></i>
        <span className="badge bg-success badge-number">{0}</span>
      </NavLink>
      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
        <li className="dropdown-header">
          You have {0} new messages
          <NavLink to="">
            <span className="badge rounded-pill bg-primary p-2 ms-2">
              View All
            </span>
          </NavLink>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
      </ul>
    </li>
  );
};

export default NavMessages;
