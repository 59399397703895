import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Header from "./components/header/Header";
import Login from "./pages/login/Login";
import SideBar from "./components/sidebar/SideBar";
import Main from "./components/main/Main";
import CourseManagementMain from "./components/main/CourseManagementMain";
import ContentAllocationMain from "./components/main/ContentAllocationMain";
import CourseDetailsMain from "./components/main/CourseDetailsMain";
import "./utils/browserEvents";
import DummyDataMain from "./components/main/DummyDataMain";
import CognitionAnalysisMain from "./components/main/CognitionAnalysisMain";

function App() {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/login" && <Header />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<SideBar />}>
            <Route path="dashboard" element={<Main />} />
            <Route path="analytics" element={<DummyDataMain />} />
            <Route
              path="cognition-analysis"
              element={<CognitionAnalysisMain />}
            />
            <Route
              path="content-management"
              element={<CourseManagementMain />}
            />
            <Route
              path="content-management/:courseName"
              element={<CourseDetailsMain />}
            />
            <Route
              path="content-allocation"
              element={<ContentAllocationMain />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
