import React, { useEffect, useState } from "react";
import "./coursemanagement.scss";
import Courses from "../../components/content-management/Courses";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const CourseManagement = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCourseList = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/list?collegeId=${currentUser.collegeId}`
        );
        setData(response.data);
      } catch (error) {
        setError("Error loading courses!");
      } finally {
        setLoading(false);
      }
    };
    fetchCourseList();
  }, [currentUser]);

  const handleAddClick = () => {
    setShowPopup(true);
  };

  const handleThumbnailChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const formData = new FormData();
      formData.append("courseName", courseName);
      formData.append("thumbnail", thumbnail);
      formData.append("duration", duration);

      const response = await axios.post(
        `${SERVER_URL}/course/add?collegeId=${currentUser.collegeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setData([...data, response.data]);
      setCourseName("");
      setThumbnail(null);
      setDuration(0);
      setShowPopup(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (updatedCourse) => {
    setData((prevData) =>
      prevData.map((course) =>
        course._id === updatedCourse._id ? updatedCourse : course
      )
    );
  };

  const handleDelete = (courseId) => {
    setData((prevData) => prevData.filter((course) => course._id !== courseId));
  };

  return (
    <div className="content-management-container">
      <div className="management-top">
        <div className="management-heading">
          <h1>
            <i className="bi bi-card-list"></i>Content Management
          </h1>
          <span>Content Management</span>
        </div>
        <div className="management-action">
          <div className="button" onClick={handleAddClick}>
            <div className="icon">
              <i className="bi bi-plus-lg"></i>
            </div>
            <span>Add Course</span>
          </div>
        </div>
      </div>
      <div className="management-bottom">
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        <div className="content-wrapper">
          {data.map((item, index) => (
            <Courses
              data={item}
              key={index}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <button className="close-button" onClick={handleClose}>
              &times;
            </button>
            <h2>Add Course</h2>
            <form method="POST" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                  required
                />
                <label htmlFor="name">Course Name</label>
              </div>
              <div className="form-group">
                <input
                  type="file"
                  onChange={handleThumbnailChange}
                  id="file"
                  name="file"
                  required
                />
                <label htmlFor="file">Upload Thumbnail</label>
              </div>
              <div className="form-group">
                <input
                  type="number"
                  id="duration"
                  name="duration"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                  required
                />
                <label htmlFor="duration">Duration In Days</label>
              </div>
              <div className="form-actions">
                <button type="submit" disabled={loading}>
                  {loading ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
            {error && <p className="error">{error}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseManagement;
