// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  padding: 10px;
}
.student-container:hover {
  background: #e7e7e7;
}
.student-container .student-details {
  display: flex;
  flex-direction: column;
}
.student-container .student-details h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}
.student-container .student-details p {
  margin-bottom: 0;
}
.student-container .progress-details {
  width: 80px;
  height: 80px;
  position: relative;
}
.student-container .progress-details svg {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));
}
.student-container .progress-details:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  filter: blur(6px);
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/progressdata.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AAAN;AAGI;EACE,gBAAA;AADN;AAKE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAHJ;AAKI;EACE,iDAAA;AAHN;AAMI;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,8BAAA;EACA,iBAAA;EACA,WAAA;AAJN","sourcesContent":[".student-container {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  align-items: center;\n  padding: 10px;\n\n  &:hover {\n    background: #e7e7e7;\n  }\n\n  .student-details {\n    display: flex;\n    flex-direction: column;\n\n    h2 {\n      font-size: 18px;\n      font-weight: 700;\n      margin-bottom: 0;\n    }\n\n    p {\n      margin-bottom: 0;\n    }\n  }\n\n  .progress-details {\n    width: 80px;\n    height: 80px;\n    position: relative;\n    \n    svg {\n      filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.2));\n    }\n\n    &:before {\n      content: \"\";\n      position: absolute;\n      top: 5px;\n      left: 5px;\n      width: 70px;\n      height: 70px;\n      border-radius: 50%;\n      background: rgba(0, 0, 0, 0.1);\n      filter: blur(6px);\n      z-index: -1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
