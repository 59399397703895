import React from "react";
import "./progressdata.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressData = ({ data }) => {
  const progressValue = parseFloat(data.progress.replace("%", ""));

  return (
    <div className="student-container">
      <div className="student-details">
        <h2>{data.email}</h2>
        <p>Course: {data.course}</p>
        <span>Chapter: {data.chapter}</span>
      </div>
      <div className="progress-details">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${progressValue}%`}
          value={progressValue}
          styles={buildStyles({
            textSize: "16px",
            pathColor: "#8c52ff",
            textColor: "#8c52ff",
            trailColor: "#d6d5d5",
            pathTransition: "stroke-dashoffset 0.5s ease 0s",
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(140, 82, 255, 0.3)",
            backgroundColor: "#f0f0f0",
          })}
        />
      </div>
    </div>
  );
};

export default ProgressData;
